import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import classnames from 'classnames'
import Seo from 'components/seo'
import Button from 'components/Base/Button'
import Banner from 'components/Banner'
import SectionSubstance, { SectionSubstanceBody } from 'components/IterativeComponent/SectionSubstance'
import MousewheelSwiper from 'components/IterativeComponent/MousewheelSwiper'
import ProductCard from 'components/IterativeComponent/ProductCard'
import PartnerCard from 'components/PartnerCard'
import CardList from 'components/IterativeComponent/CardList'
import CargoCard from 'components/IterativeComponent/CargoCard'
import CourseCard from 'components/IterativeComponent/CourseCard'
import Panorama from 'components/IterativeComponent/Panorama'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import ArticleFooter from 'components/IterativeComponent/ArticleFooter'
import SectionSwiperMobile from 'components/IterativeComponent/SectionSwiperMobile'

import Layout from 'layouts/zh'

import * as styles from './index.module.less'
const BannerSlot: string = 'lg:block absolute hidden text-[12px] leading-[17px] text-[#3f4141] font-bold'

const SaasNew: React.FC = (props) => {
  const { saasNewYaml: datas } = useStaticQuery(graphql`
    query {
      saasNewYaml {
        title
        description
        keywords
        saTitle
        banner_Desc
        banner_buttonHref
        banner_buttonText
        banner_slot_marketing
        banner_slot_products
        banner_slot_service
        banner_imgUrl_icon
        banner_imgUrl_mb
        banner_dataSource {
          buttonHref
          buttonText
          dataDesc
          dataTitle
          descList
          qrcodeUrl
        }
        banner_sectionTwoDesc
        banner_sectionTwoTitle
        banner_sectionTwoTitleMb
        banner_title
        banner_titleMb
        banner_qrcodeButtonText
        cargo_dataList {
          btnHref
          btnText
          dataMsg
          dataTitle
          imgUrl
          imgUrlMb
        }
        cargo_title
        cargo_titleMb
        digitization_btnHref
        digitization_btnText
        digitization_desc
        digitization_orderHref
        digitization_orderText
        digitization_title
        panorama_btnText
        panorama_btnCode
        panorama_desc
        panorama_imgUrl
        panorama_title
        panorama_titleMb
        panorama_btnText_two
        panorama_btnText_two_url
        product_dataList {
          btnHref
          dataDsc
          dataMsg
          btnText
          dataTitle
          imgUrl
          imgUrlMb
        }
        product_title
        product_titleMb
        software_sectionCardList {
          cover
          desc
          title
        }
        software_title
        software_titleMb
        software_buttonText
        software_qrcode
        tools_buttonLink
        tools_buttonText
        tools_descList {
          list
          value
        }
        tools_thumb
        tools_title
        partner_buttonText
        partner_dataList {
          imgUrl
        }
        partner_desc
        partner_imgBottomText
        partner_qrcode
        partner_title
        partner_titleMb
        partner_voice_imgUrl
        partner_voice_imgUrlMb
        partner_voice_icon_imgUrlMb
      }
    }
  `)

  return (
    <Layout {...props}>
      <SaasNewState datas={datas} />
    </Layout>
  )
}

interface SaasNewStateProps {
  datas: any
}
export const SaasNewState: React.FC<SaasNewStateProps> = (props) => {
  const { datas } = props

  return (
    <>
      <Seo title={datas?.title} description={datas?.description} keywords={datas?.keywords} saTitle={datas?.saTitle} />
      <main className={classnames('pt-[4rem] lg:pt-[64px] relative', styles.SaasNew)}>
        {/* Section 1 */}
        <Banner
          slotTag={
            <>
              <div className={classnames(styles.BannerSlotMarketing, BannerSlot, 'right-[200px] top-[114px]')}>
                {datas?.banner_slot_marketing}
              </div>
              <div className={classnames(styles.BannerSlotProducts, BannerSlot, 'right-[180px] top-[159px]')}>
                {datas?.banner_slot_products}
              </div>
              <div className={classnames(styles.BannerSlotService, BannerSlot, 'right-[204px] top-[204px]')}>
                {datas?.banner_slot_service}
              </div>
            </>
          }
          bannerIconClassName={styles.BannerIcon}
          className={styles.Banner}
          bannerBackground="#F0E7F8"
          bannerIcon={datas?.banner_imgUrl_icon}
          bannerImgMb={datas?.banner_imgUrl_mb}
          title={datas?.banner_title}
          desc={datas?.banner_Desc}
          titleMb={datas?.banner_titleMb}
          btnText={datas?.banner_buttonText}
          btnHref={datas?.banner_buttonHref}
        />
        {/* Section 2 */}
        <SectionSubstance
          style={{ paddingBottom: 0 }}
          className={classnames('relative z-10')}
          title={datas?.banner_sectionTwoTitle}
          titleMb={datas?.banner_sectionTwoTitleMb}
          descText={datas?.banner_sectionTwoDesc}
        >
          <MousewheelSwiper
            className="hidden lg:block"
            dataSource={datas?.banner_dataSource.map((item) => ({
              ...item,
              imgUrl: item?.qrcodeUrl.publicURL || item?.qrcodeUrl,
            }))}
          />
          <SectionSwiperMobile
            className="block lg:hidden mt-[2rem]"
            dataSource={datas?.banner_dataSource.map((item) => ({
              ...item,
              imgUrl: item?.qrcodeUrl.publicURL || item?.qrcodeUrl,
            }))}
            slotButton={
              <Button btnType="primary" href={datas?.banner_buttonHref} target="_blank">
                {datas?.banner_buttonText}
              </Button>
            }
          />
        </SectionSubstance>
        {/* Section 3 */}
        <SectionSubstance title={datas?.software_title} titleMb={datas?.software_titleMb}>
          <SectionSubstanceBody>
            <CardList
              isHeight={false}
              cardClassName={styles.Card}
              titleColor="#1F2D3D"
              coverWidth={280}
              coverHeight={156}
              autoplay
              yGap={60}
              xGap={32}
              mYGap={20}
              mXGap={0}
              datas={datas?.software_sectionCardList}
            />
            <div className="text-center">
              <ScanCodeConsultButton
                buttonText={datas?.software_buttonText}
                qrcode={datas?.software_qrcode?.publicURL || datas?.software_qrcode}
              />
            </div>
          </SectionSubstanceBody>
        </SectionSubstance>
        {/* Section 4 */}
        <SectionSubstance
          style={{ paddingTop: '57px', paddingBottom: '57px', backgroundColor: '#F5F4FA' }}
          className={styles.CourseCard}
          titleMb={datas?.tools_title}
        >
          <CourseCard
            title={datas?.tools_title}
            thumb={datas?.tools_thumb}
            buttonText={datas?.tools_buttonText}
            buttonHref={datas?.tools_buttonLink}
            descList={datas?.tools_descList}
          />
        </SectionSubstance>
        {/* Section 5 */}
        <SectionSubstance
          titleMb={datas?.panorama_titleMb}
          title={datas?.panorama_title}
          descText={datas?.panorama_desc}
        >
          <Panorama
            altTitle={datas?.panorama_title}
            btnText={datas?.panorama_btnText}
            btnCode={datas?.panorama_btnCode?.publicURL || datas?.panorama_btnCode}
            imgUrl={datas?.panorama_imgUrl?.publicURL || datas?.panorama_imgUrl}
            btnTextTwo={datas?.panorama_btnText_two}
            btnTextTwoUrl={datas?.panorama_btnText_two_url}
          />
        </SectionSubstance>
        {/* Section 6 */}
        <SectionSubstance
          className={classnames('bg-[#F6F9FF]', styles.partner)}
          titleMb={datas?.partner_titleMb}
          descText={datas?.partner_desc}
          title={datas?.partner_title}
        >
          <div className="lg:w-[996px] mt-[30px] lg:mx-auto lg:mt-[40px] relative">
            <img
              src={datas?.partner_voice_imgUrl?.publicURL || datas?.partner_voice_imgUrl}
              className="hidden lg:block"
              alt=""
            />
            <img
              style={{
                boxShadow: '0px 2px 20px 0px rgba(153, 169, 191, 0.18)',
              }}
              src={datas?.partner_voice_imgUrlMb?.publicURL || datas?.partner_voice_imgUrlMb}
              className="block lg:hidden"
            />
            {/* <span className="absolute  lg:hidden w-[12.7rem] h-[3.3rem] bottom-[2.6rem] right-[2rem]">
              <img src={datas?.partner_voice_icon_imgUrlMb?.publicURL || datas?.partner_voice_icon_imgUrlMb} />
            </span> */}
          </div>
          <PartnerCard
            className="pt-[2rem] lg:pt-[20px]"
            dataSource={datas?.partner_dataList.map((item) => ({
              ...item,
              imgUrl: item?.imgUrl?.publicURL || item?.imgUrl,
            }))}
          />
          <div className="text-center mt-[3rem] lg:mt-[40px]">
            <ScanCodeConsultButton
              imgBottomText={datas?.partner_imgBottomText}
              buttonText={datas?.partner_buttonText}
              qrcode={datas?.partner_qrcode?.publicURL || datas?.partner_qrcode}
            />
          </div>
        </SectionSubstance>
        {/* section 7 更多干货 */}
        <SectionSubstance style={{ paddingBottom: 0 }} titleMb={datas?.cargo_titleMb} title={datas?.cargo_title}>
          <CargoCard
            className={classnames('lg:pt-[40px] pt-[2rem]', styles.CargoCard)}
            dataSource={datas?.cargo_dataList?.map((item) => ({
              ...item,
              imgUrl: item?.imgUrl?.publicURL || item?.imgUrl,
              imgUrlMb: item?.imgUrlMb?.publicURL || item?.imgUrlMb,
            }))}
          />
        </SectionSubstance>
        {/* section 8 了解神策产品 */}
        <SectionSubstance title={datas?.product_title} titleMb={datas?.product_titleMb}>
          <ProductCard
            className="lg:pt-[40px] pt-[2rem]"
            dataSource={datas?.product_dataList?.map((item) => ({
              ...item,
              imgUrl: item?.imgUrl?.publicURL || item?.imgUrl,
              imgUrlMb: item?.imgUrlMb?.publicURL || item?.imgUrlMb,
            }))}
          />
        </SectionSubstance>
        {/* 尾部 */}
        <ArticleFooter
          className={styles.automotiveNewFooter}
          title={datas?.digitization_title}
          gameDsc={datas?.digitization_desc}
          btnText={datas?.digitization_btnText}
          btnHref={datas?.digitization_btnHref}
          orderText={datas?.digitization_orderText}
          orderHref={datas?.digitization_orderHref}
        />
      </main>
    </>
  )
}

export default SaasNew
